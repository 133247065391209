import React, { useState, useEffect, useCallback } from "react"
import { graphql, Link, navigate } from "gatsby"
import { DebounceInput } from "react-debounce-input"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import LoadMoreButton from "../components/addon/loadMoreButton"
import Loader from "../components/addon/Loader"
import { DOCTORS_FILTER } from "../service/config"
import API from "../api/doctors"

import { Typeahead, withAsync } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import { useLocation } from '@reach/router';

const AsyncTypeahead = withAsync(Typeahead)

const getSelect = (props) => {
    const { onSpecialitySelect, specialitySelect, specialities } = props
    return (
        <select aria-label="Search filter" name="Search_by_filter" id=""
            className="form-control" onChange={onSpecialitySelect} value={specialitySelect}>
            <option value="">Speciality
            </option>
            {
                (specialities && specialities.length > 0) &&
                specialities.map((el, index) => {
                    return (
                        <option key={index} value={el.id}>{el.name}
                        </option>
                    )
                })
            }
        </select>
    )
}

const DoctorsListing = (props) => {
    const location = useLocation();
    const defaultImage = "https://via.placeholder.com/84x84"
    const [specialities, setSpecialities] = useState([])
    let [defaultLimit] = useState(9)
    let [displayData, setDisplayData] = useState([])
    let [doctorsList, setDoctorsList] = useState([])
    let [selectedLetter, setSelectedLetter] = useState("")
    let [loading, setLoading] = useState(false)
    let [showNoDataText, setShowNoDataText] = useState(false)
    let [queryParams, setQueryParams] = useState({})
    let [specialitySelect, setSpecialitySelect] = useState(null)

    let [searchList, setSearchList] = useState([])
    let [searchLoading, setSearchLoading] = useState(false)
    let [searchPlaceHolder, setSearchPlaceholder] = useState("Search by Doctor’s Name")
    const [filterOpen, setFilterOpen] = useState(false)
    const typeAheadRef = React.createRef()

    const metaTags = props?.data.allNodeTemplatePages.edges[0]?.node?.metatags ? props?.data.allNodeTemplatePages.edges[0].node.metatags : []
    const schema = props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema ? props?.data.allNodeTemplatePages.edges[0]?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = props?.data?.allNodeTemplatePages.edges[0].node?.metatags_on_page || {}

    useEffect(() => {
        getSpecialities()
        const params = new URLSearchParams(document.location.search.substring(1))
        if (params && params.get("category")) {
            const category = params.get("category")
            if (category !== null) {
                setSpecialitySelect(category)
            }
        } else {
            getData()
        }
    }, [])

    useEffect(() => {
        const criteriaArr = Object.keys(queryParams).filter(k => {
            if (queryParams[k] === null) {
                return false;
            }
            return true;
        })
        if (criteriaArr.length > 0) {
            getData()
        }

    }, [queryParams])

    useEffect(() => {
        filterBySpeciality(specialitySelect)
    }, [specialitySelect])

    useEffect(() => {
        if (doctorsList.length === 0) {
            setDisplayData([])
        } else if (doctorsList.length <= defaultLimit)
            setDisplayData([...doctorsList])
        else {
            loadMoreData()
        }
    }, [doctorsList])

    const filteLastNameBeginsWith = (letter) => {
        setLoader()
        setDoctorsList([])
        setSelectedLetter(letter)
        setQueryParams({
            starts_with: letter
        })
    }
    const filterBySpeciality = (value) => {
        setLoader()
        setDoctorsList([])
        setSelectedLetter("")
        if (value === "") {
            setQueryParams({
                speciality: ""
            })
        } else {
            setQueryParams({
                speciality: value
            })
        }

    }
    const filterByFirstName = (query) => {
        setSearchLoading(true)
        API.findDoctors(query)
            .then(response => {
                if (response && response?.data && response.data.length) {
                    let arr = response?.data.map(el => {
                        return {
                            id: el?.path,
                            path: el?.path,
                            name: el?.name,
                            searchValue: query
                        }
                    })
                    setSearchList(arr)
                } else {
                    setSearchList([])
                }
                setSearchLoading(false)
            })
            .catch(err => {
                console.log(err)
                setSearchLoading(false)
            })

    }

    const onSearchTextChange = (searchText) => {
        const text = searchText
        if (text) {
            filterByFirstName(text)
        } else if (text.trim() === '') {
            clearData()
        }
    }

    const clearData = () => {
        setDoctorsList([])
        setSelectedLetter("")
        getData()
    }
    const setLoader = () => {
        setLoading(true)
        setShowNoDataText(false)
    }

    const getData = () => {
        setLoader()
        const q = queryParams;

        if (q.speciality === "") {
            q.speciality = null
        }

        API.getDoctors(q)
            .then(response => {
                console.log('resss', response)
                if (response && response?.data && response.data.length) {
                    //Sorting logic
                    let arr = JSON.parse(JSON.stringify(response.data));
                    arr.sort((a, b) => {
                        const x = a.hod_doctor === "1" ? true : false;
                        const y = b.hod_doctor === "1" ? true : false;
                        return (x === y) ? 0 : x ? -1 : 1;
                    })
                    if (q?.speciality?.length) {
                        arr.sort((a, b) => {
                            let x = a?.doc_weight_wrt_speciality?.[q?.speciality]?.[0]?.doctor_speciality_weight
                            let y = b?.doc_weight_wrt_speciality?.[q?.speciality]?.[0]?.doctor_speciality_weight
                            if (Number(x) < Number(y)) return -1
                            if (Number(x) > Number(y)) return 1
                            return 0
                        })
                    }
                    // doc list filter by name
                    // arr.sort((a, b) => {
                    //     if(a?.name < b?.name) return -1
                    //     if(a?.name > b?.name) return 1
                    //     return 0
                    // }
                    setDoctorsList(arr)
                    setShowNoDataText(false)
                } else {
                    setDoctorsList([])
                    setShowNoDataText(true)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setShowNoDataText(true)
            })
    }

    const loadMoreData = () => {
        const counter = displayData.length + defaultLimit
        const arr = [...doctorsList.slice(displayData.length, counter)]
        setDisplayData([...displayData, ...arr])
    }


    const getSpecialities = () => {
        API.getSpecialities()
            .then(response => {
                if (response && response.length) {
                    let arr = [];
                    response.forEach(el => {
                        arr.push({
                            name: el?.name,
                            id: el?.name
                        })
                        if (el.children) {
                            const t = el.children.map(c => {
                                return {
                                    name: c?.name,
                                    id: c?.name
                                }
                            })
                            arr = [...arr, ...t];
                        }
                    })
                    arr.sort(function (a, b) {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                    })
                    setSpecialities(arr)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onSpecialitySelect = (event) => {
        if (event.target.value !== 'default') {
            setSpecialitySelect(event.target.value)
        }
    }

    const onSearchTextSelect = data => {
        if (data && data.length > 0) {
            const obj = data[0]
            if (obj.path) {
                navigate(obj.path)
            }
        }
    }

    const onFilterClick = useCallback(() => {
        setFilterOpen(!filterOpen)
    }, [filterOpen])

    return (
        <Layout>
            <Meta
                files={
                    {
                        js: [],
                        css: ["/assets/css/listing.css"]
                    }
                }
                tags={{metaTags,canonicalUrl}}
            />
            <Schema schema={schema} />
            <main className="innerpage pt-first-section">
                <section className="Inner_banner">
                    <div className="Inner_banner_content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 col-md-6">
                                    <div className="title_with_image">
                                        <h1>Find A Doctor</h1>
                                        <img src="/assets/images/listing/find-doctors-icon.svg" alt="find doctors icon" title="find doctors icon" />
                                    </div>
                                    <p>The best doctors to address your health condition. Pick the one that’s right for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <picture className="hero_image text-center">
                        <source media="(min-width:1024px)"
                            srcset={`/assets/images/listing/banner-doctor-listing.jpg`} />
                        <source media="(min-width:300px)"
                            srcset={`/assets/images/listing/mobile-banner-doctor-listing.png`} />
                        <img src={`/assets/images/listing/banner-doctor-listing.jpg`} alt="" title="" />
                    </picture>
                </section>
                <section className="searchWrap_section">
                    <div className="container">
                        {/* <a href="javascript:void(0)" onClick={clearData}>CLEAR</a> */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row ml-0 mr-0">
                                    <div className="col-10 col-md-7 col-lg-8 pl-0 pr-0">
                                        <div className="input-group">
                                            <div className="form-outline">
                                                <AsyncTypeahead
                                                    delay={500}
                                                    placeholder={searchPlaceHolder}
                                                    labelKey="name"
                                                    onSearch={onSearchTextChange}
                                                    onChange={onSearchTextSelect}
                                                    isLoading={searchLoading}
                                                    options={searchList}
                                                    id="find_a_doctor_search"
                                                    searchText="Searching..."
                                                    useCache={false}
                                                    onBlur={() => setSearchPlaceholder("Search by Doctor’s Name")}
                                                    onFocus={() => setSearchPlaceholder("")}
                                                    ref={typeAheadRef}
                                                    filterBy={['name', 'id', 'path', 'searchValue']}
                                                />
                                            </div>
                                            <button type="button" className="search_btn">
                                                <img src="/assets/images/listing/search_icon.svg" alt="Search icon" title="Search icon" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-2 col-md-5 col-lg-4  pl-0 pr-0">
                                        <div className="filter_wrap">
                                            <div className="filter_section hide_on_mobile">
                                                <span className="title_filter">FILTER BY</span>
                                                <div className="form-group">
                                                    <div className="select_dropdown_section">
                                                        {getSelect({
                                                            onSpecialitySelect,
                                                            specialitySelect,
                                                            specialities
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter_section show_on_mobile">
                                                <button className="filter_btn border-0" onClick={onFilterClick}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.291" height="19.968" viewBox="0 0 17.291 19.968">
                                                        <g id="Group_1221" data-name="Group 1221" transform="translate(0.511 0.522)">
                                                            <g id="Group_1220" data-name="Group 1220" transform="translate(0 0)">
                                                                <path id="Path_2089" data-name="Path 2089" d="M126.314,45.267c-.132.185-.264.369-.393.554l-1,1.411q-.672.943-1.342,1.888-.706.995-1.413,1.987l-1.211,1.7-.75,1.054c-.039.053-.081.105-.116.162a.86.86,0,0,0-.043.408V60.27c.03-.071.059-.144.089-.215q-.541.587-1.085,1.172-.864.934-1.73,1.87c-.132.144-.266.286-.4.43l.519.215V54.284a.505.505,0,0,0-.059-.3c-.227-.349-.483-.679-.724-1.018q-.724-1.016-1.446-2.034-.855-1.2-1.707-2.4-.742-1.043-1.484-2.088-.4-.563-.8-1.127l-.036-.053-.264.458h15.023c.2,0,.41,0,.612,0h.026a.3.3,0,1,0,0-.608H111.557c-.2,0-.41,0-.612,0h-.026a.307.307,0,0,0-.264.458l.618.87,1.484,2.086,1.8,2.524,1.553,2.184.511.72c.081.114.156.233.243.341,0,0,.006.01.01.014-.014-.051-.028-.1-.041-.154v8.4c0,.387-.016.777,0,1.164v.016a.307.307,0,0,0,.519.215q.678-.736,1.359-1.47l1.758-1.9a.5.5,0,0,0,.185-.318c0-.043,0-.089,0-.132v-3.9c0-.675.018-1.352,0-2.028v-.032c-.014.051-.028.1-.041.154l.62-.872,1.49-2.095,1.8-2.524,1.557-2.19c.251-.353.523-.7.756-1.065,0,0,.006-.01.01-.014a.305.305,0,0,0-.109-.416A.315.315,0,0,0,126.314,45.267Z" transform="translate(-110.608 -45.111)" fill="#044597" stroke="#044597" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`mobileSpecialitylist ${filterOpen ? 'd-block' : 'd-none'}`}>
                                    {getSelect({
                                        onSpecialitySelect,
                                        specialitySelect,
                                        specialities
                                    })}
                                </div>
                                <div className="last_name_listwrap">
                                    <span className="heading_text pb-0">by Last name</span>
                                    <ul>
                                        {
                                            DOCTORS_FILTER.map((el, index) => {
                                                return (
                                                    <li
                                                        key={index}
                                                        className={`${el === selectedLetter ? 'active' : ''}`}
                                                    >
                                                        <a
                                                            onClick={() => filteLastNameBeginsWith(el)}
                                                            href="javascript:void(0)">
                                                            {el}</a>
                                                    </li>
                                                )
                                            })
                                        }
                                        <li><a
                                            onClick={() => filteLastNameBeginsWith("")}
                                            href="javascript:void(0)">
                                            All</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="common_speciality_wrap">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="speciality_section">
                                                <h4 className="top_title">Common Specialities</h4>
                                                <ul>
                                                    <li><a href="javascript:;">Homeopath</a></li>
                                                    <li><a href="javascript:;">Ayurveda</a></li>
                                                    <li><a href="javascript:;">ENT Specialist</a></li>
                                                    <li><a href="javascript:;">General Physician</a></li>
                                                    <li><a href="javascript:;">Dermatologist</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="speciality_section">
                                                <h4 className="top_title">Popular Searches</h4>
                                                <ul>
                                                    <li><a href="javascript:;">Dentist</a></li>
                                                    <li><a href="javascript:;">Allergy</a></li>
                                                    <li><a href="javascript:;">ENT</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="inner_three_cards dr-listing-boxes">
                    <div className="container">
                        <div className="row">
                            {
                                (displayData && displayData.length > 0) &&
                                displayData.map((el, index) => {
                                    let p_n_r;
                                    if (el?.speciality) {
                                        Object.keys(el?.speciality).forEach(i => {
                                            p_n_r = el?.speciality[i]?.deptName === 'Physiotherapy & Rehabilitation';
                                        })
                                    }
                                    return (
                                        <div key={index} className="col-lg-4 col-md-6">
                                            <div className="card">
                                                <div className="card_image">
                                                    <span className="imageCircle">

                                                        {el?.path
                                                            ? <Link to={el.path}><img src={el?.image || defaultImage} alt={el.name || ""} /></Link>
                                                            : <><img src={el?.image || defaultImage} alt={el.name || ""} /></>}
                                                    </span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="top_title">
                                                        <h3>
                                                            {/* {!p_n_r && "Dr. "} */}
                                                            {el?.path ? <Link to={el.path}>{el.name || ""}</Link> : <>{el.name || ""}</>}
                                                        </h3>
                                                        <span className="sub_title mb-1"><strong>{el?.designation || ''}</strong></span>
                                                        <span className="sub_title">{el?.qualification || ''}</span>
                                                        <p>{el?.experience || ''}</p>
                                                    </div>
                                                    <div className="card_link">
                                                        {
                                                            el.path &&
                                                            <Link to={el.path} className="btn btn-primary" aria-label={el.name || ""}>{el.avail_for_online_booking !== "1" ? "Book Appointment" : "View Profile"}</Link>
                                                        }
                                                    </div>
                                                    {/* <p><span className="webrupee">`</span> 100 Off on Online Booking</p> */}
                                                    {/* Keep it static */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (displayData.length != doctorsList.length && !loading) &&
                                <div className="col-lg-12">
                                    <div className="card_link load_more">
                                        <LoadMoreButton
                                            label="Load More"
                                            callbackFunction={loadMoreData}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                loading &&
                                <div className="col-lg-12">
                                    <Loader loadingMessage="Loading..." />
                                </div>
                            }
                        </div>
                        {
                            showNoDataText === true &&
                            <p>No doctors found.</p>
                        }
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
query findADoctorQuery {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/find-a-doctor$/"}}}) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          relationships{
            field_seo_schema{
                field_seo_title
                field_seo_schema_text_area
            }
          }
          metatags: metatag_normalized {
            attributes {
              content
              name
              property
            }
          }
          metatags_on_page: field_meta_tags{
            canonical_url
          }
        }
      }
    }
  }  
`

export default DoctorsListing